<template>
  <div class="one_test">
    <!-- 选项卡 -->
    <div class="option_box">
      <div class="option_content_box">
        <!-- 题目卡片 -->
        <!-- v-if="index === page" -->
        <div class="option_content">
          <!-- 单选/多选/ -->
          <div id="radio" class="bt" v-if="a.length > 0">一、单选题</div>
          <div class="option_name" v-if="a.length > 0">
            <div class="card" v-for="(item, i) in a" :key="i + 'a'">
              <Question1
                :data="item"
                :type="2"
                :isReveal="isReveal"
                :is_num="i + 1"
                @isTNum="isTNum"
              />
              <!-- 收藏 -->
              <div style="display: flex; align-items: center;justify-content: space-between;">
                <div></div>
                <div style="display: flex; align-items: center;">
                <div
                  v-if="item.is_collect == 0"
                  class="collect_box"
                  @click="collect(i, 'a')"
                >
                  <img src="../../assets/img/collect.png" alt="" />
                  收藏本题
                </div>
                <div
                  v-if="item.is_collect == 1"
                  class="collect_box no_collect"
                  @click="delcollect(i, 'a')"
                >
                  <img src="../../assets/img/quxiao.png" alt="" />
                  取消收藏
                </div>
                <div
                  style="
                    width: 1px;
                    height: 17px;
                    background: #e5e5e5;
                    border-radius: 1px;
                    margin: 0 20px;
                  "
                ></div>
                <div class="collect_box" @click="errorCorrection(item)">
                  <img src="../../assets/img/jiucuo.png" alt="" />
                  试题纠错
                </div>
                </div>
              </div>
            </div>
          </div>

          <div id="peiwu" class="bt" v-if="b.length > 0">二、配伍题</div>

          <div class="option_name" v-if="b.length > 0">
            <div class="card" v-for="(item, i) in b" :key="i + 'b'">
              <Question2
                :data="item"
                :type="2"
                :isReveal="isReveal"
                :is_num="i + 1"
                @isTNum="isTNum"
              />
              <!-- 收藏 -->
              <div style="display: flex; align-items: center;justify-content: space-between;margin-top: -48px;">
                <div></div>
                <div style="display: flex; align-items: center;">
                <div
                  v-if="item.is_collect == 0"
                  class="collect_box"
                  @click="collect(i, 'b')"
                >
                  <img src="../../assets/img/collect.png" alt="" />
                  收藏本题
                </div>
                <div
                  v-if="item.is_collect == 1"
                  class="collect_box no_collect"
                  @click="delcollect(i, 'b')"
                >
                  <img src="../../assets/img/quxiao.png" alt="" />
                  取消收藏
                </div>
                <div
                  style="
                    width: 1px;
                    height: 17px;
                    background: #e5e5e5;
                    border-radius: 1px;
                    margin: 0 20px;
                  "
                ></div>
                <div class="collect_box" @click="errorCorrection(item)">
                  <img src="../../assets/img/jiucuo.png" alt="" />
                  试题纠错
                </div>
                </div>
              </div>
            </div>
          </div>

          <div id="stuff_case" class="bt" v-if="c.length > 0">
            三、综合分析选择题
          </div>

          <div class="option_name" v-if="c.length > 0">
            <div class="card" v-for="(item, i) in c" :key="i + 'c'">
              <Question3
                :data="item"
                :type="2"
                :isReveal="isReveal"
                :is_num="i + 1"
                @isTNum="isTNum"
              />
              <!-- 收藏 -->
              <div style="display: flex; align-items: center;justify-content: space-between;margin-top: -35px;">
                <div></div>
                <div style="display: flex; align-items: center;">
                <div
                  v-if="item.is_collect == 0"
                  class="collect_box"
                  @click="collect(i, 'c')"
                >
                  <img src="../../assets/img/collect.png" alt="" />
                  收藏本题
                </div>
                <div
                  v-if="item.is_collect == 1"
                  class="collect_box no_collect"
                  @click="delcollect(i, 'c')"
                >
                  <img src="../../assets/img/quxiao.png" alt="" />
                  取消收藏
                </div>
                <div
                  style="
                    width: 1px;
                    height: 17px;
                    background: #e5e5e5;
                    border-radius: 1px;
                    margin: 0 20px;
                  "
                ></div>
                <div class="collect_box" @click="errorCorrection(item)">
                  <img src="../../assets/img/jiucuo.png" alt="" />
                  试题纠错
                </div>
                </div>
              </div>
            </div>
          </div>

          <div id="checkbox" class="bt" v-if="d.length > 0">四、多选题</div>
          <div class="option_name" v-if="d.length > 0">
            <div class="card" v-for="(item, i) in d" :key="i + 'd'">
              <Question4
                :data="item"
                :type="2"
                :isReveal="isReveal"
                :is_num="i + 1"
                @isTNum="isTNum"
              />
              <!-- 收藏 -->
              <div style="display: flex; align-items: center;justify-content: space-between;">
                <div></div>
                <div style="display: flex; align-items: center;">
                <div
                  v-if="item.is_collect == 0"
                  class="collect_box"
                  @click="collect(i, 'd')"
                >
                  <img src="../../assets/img/collect.png" alt="" />
                  收藏本题
                </div>
                <div
                  v-if="item.is_collect == 1"
                  class="collect_box no_collect"
                  @click="delcollect(i, 'd')"
                >
                  <img src="../../assets/img/quxiao.png" alt="" />
                  取消收藏
                </div>
                <div
                  style="
                    width: 1px;
                    height: 17px;
                    background: #e5e5e5;
                    border-radius: 1px;
                    margin: 0 20px;
                  "
                ></div>
                <div class="collect_box" @click="errorCorrection(item)">
                  <img src="../../assets/img/jiucuo.png" alt="" />
                  试题纠错
                </div>
                </div>
              </div>
            </div>
          </div>

          <div id="stuff" class="bt" v-if="e.length > 0">五、案例分析题</div>
          <div class="option_name" v-if="e.length > 0">
            <div class="card" v-for="(item, i) in e" :key="i + 'e'">
              <Question5
                :data="item"
                :type="2"
                :isReveal="isReveal"
                :is_num="i + 1"
                @isTNum="isTNum"
              />
              <!-- 收藏 -->
              <div style="display: flex; align-items: center;justify-content: space-between;">
                <div></div>
                <div style="display: flex; align-items: center;">
                <div
                  v-if="item.is_collect == 0"
                  class="collect_box"
                  @click="collect(i, 'e')"
                >
                  <img src="../../assets/img/collect.png" alt="" />
                  收藏本题
                </div>
                <div
                  v-if="item.is_collect == 1"
                  class="collect_box no_collect"
                  @click="delcollect(i, 'e')"
                >
                  <img src="../../assets/img/quxiao.png" alt="" />
                  取消收藏
                </div>
                <div
                  style="
                    width: 1px;
                    height: 17px;
                    background: #e5e5e5;
                    border-radius: 1px;
                    margin: 0 20px;
                  "
                ></div>
                <div class="collect_box" @click="errorCorrection(item)">
                  <img src="../../assets/img/jiucuo.png" alt="" />
                  试题纠错
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 试题纠错弹窗 -->
    <div class="wrongmask_box" v-show="show_wrongmask == true">
      <div class="mask_box">
        <img
          src="../../assets/img/close.png"
          alt=""
          @click="show_wrongmask = false"
        />
        <div class="wrong_content">
          <div class="wrong_title">试题纠错</div>
          <div class="wrong_text">
            非常感谢您向我们提出错误，我们将尽力为您提供最优质的服务
          </div>
          <div class="wrong_text2">错误描述</div>
          <div class="wrong_ipt_box">
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              maxlength="300"
              v-model="suggest"
            ></textarea>
            <p>{{ suggest.length }}/300</p>
          </div>
          <div class="submit_box">
            <button type="button" @click="show_wrongmask = false">取消</button>
            <button type="button" @click="submitError">提交纠错</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import Question1 from "../question_1/index.vue";
import Question2 from "../question_2/index.vue";
import Question3 from "../question_3/index.vue";
import Question4 from "../question_4/index.vue";
import Question5 from "../question_5/index.vue";
export default {
  name: "All_test",
  props: {
    data: Array,
  },
  components: {
    Question1,
    Question2,
    Question3,
    Question4,
    Question5,
  },
  data() {
    return {
      topic_id: "", // 题目id
      topic_type: "", // 题目类型
      suggest: "",
      show_wrongmask: false,
      isReveal: false,
      a: [],
      b: [],
      c: [],
      d: [],
      e: [],
    };
  },
  beforeCreate() {},
  watch: {},
  created() {
    let a = this.data.filter((e) => {
      return e.qtype == 1;
    });
    let b = this.data.filter((e) => {
      return e.qtype == 2;
    });
    let c = this.data.filter((e) => {
      return e.qtype == 3;
    });
    let d = this.data.filter((e) => {
      return e.qtype == 4;
    });
    let e = this.data.filter((e) => {
      return e.qtype == 5;
    });
    this.a = a;
    this.b = b;
    this.c = c;
    this.d = d;
    this.e = e;
  },
  mounted() {},
  methods: {
    //第几题
    isTNum(val) {
      console.log(val);
      this.$emit("isTNum", val);
    },
    //单选
    pick(val) {
      let paper = this.$store.state.paper;
      paper[val.index] = val.answer;
      this.$store.commit("PaperState", paper);
      this.paper = paper;
      this.$forceUpdate();
    },
    //多选
    checkall(val) {
      let paper = this.$store.state.paper;
      if (paper[val.index]) {
        let a = paper[index].join(",");
        if (a.indexOf(val.answer)) {
          a.splice(a[a.indexOf(val.answer)], 1);
        } else {
          a.push(val.answer);
        }
        paper[val.index] = a.toString();
        this.$store.commit("PaperState", paper);
      } else {
        paper[val.index] = val.answer;
        this.$store.commit("PaperState", paper);
      }
      this.paper = paper;
    },
    // 收藏试题
    collect(i, t) {
      switch (t) {
        case 'a':
            // console.log(this.a[i])
          if (this.a[i].is_collect == 0) {
            let data = {
              chapter_category: this.a[i].two_chaptercate_id,
              three_category_id:this.a[i].chapter_category_id,
              ex_id: this.a[i].id,
              qtype: this.a[i].qtype,
            };
            api.add_collect(data).then((res) => {
              // console.log("收藏", res);
            //   if (parseInt(res.data.code) == 1) {
                // // this.my_like = true;
                this.a[i].is_collect = 1;
                this.$message.success({
                  duration: 2000,
                  message: "收藏成功!",
                });
            //   }
            });
          } else {
            // this.my_like = false;
            this.$message.error({
              duration: 2000,
              message: "本题已收藏!",
            });
          }
          break;
          case 'b':
          if (this.b[i].is_collect == 0) {
            let data = {
              chapter_category: this.b[i].two_chaptercate_id,
              ex_id: this.b[i].id,
              three_category_id:this.a[i].chapter_category_id,
              qtype: this.b[i].qtype,
            };
            api.add_collect(data).then((res) => {
              // console.log("收藏", res);
            //   if (parseInt(res.data.code) == 1) {
                // // this.my_like = true;
                this.b[i].is_collect = 1;
                this.$message.success({
                  duration: 2000,
                  message: "收藏成功!",
                });
            //   }
            });
          } else {
            // this.my_like = false;
            this.$message.error({
              duration: 2000,
              message: "本题已收藏!",
            });
          }
          break;
          case 'c':
          if (this.c[i].is_collect == 0) {
            let data = {
              chapter_category: this.c[i].two_chaptercate_id,
              ex_id: this.c[i].id,
              three_category_id:this.a[i].chapter_category_id,
              qtype: this.c[i].qtype,
            };
            api.add_collect(data).then((res) => {
              // console.log("收藏", res);
            //   if (parseInt(res.data.code) == 1) {
                // // this.my_like = true;
                this.c[i].is_collect = 1;
                this.$message.success({
                  duration: 2000,
                  message: "收藏成功!",
                });
            //   }
            });
          } else {
            // this.my_like = false;
            this.$message.error({
              duration: 2000,
              message: "本题已收藏!",
            });
          }
          break;
          case 'd':
          if (this.d[i].is_collect == 0) {
            let data = {
              chapter_category:this.d[i].two_chaptercate_id,
              ex_id:this.d[i].id,
              three_category_id:this.a[i].chapter_category_id,
              qtype:this.d[i].qtype,
            };
            api.add_collect(data).then((res) => {
              // console.log("收藏", res);
            //   if (parseInt(res.data.code) == 1) {
                // // this.my_like = true;
               this.d[i].is_collect = 1;
                this.$message.success({
                  duration: 2000,
                  message: "收藏成功!",
                });
            //   }
            });
          } else {
            // this.my_like = false;
            this.$message.error({
              duration: 2000,
              message: "本题已收藏!",
            });
          }
          break;
          case 'e':
          if (this.e[i].is_collect == 0) {
            let data = {
              chapter_category: this.e[i].two_chaptercate_id,
              ex_id: this.e[i].id,
              three_category_id:this.a[i].chapter_category_id,
              qtype: this.e[i].qtype,
            };
            api.add_collect(data).then((res) => {
              // console.log("收藏", res);
            //   if (parseInt(res.data.code) == 1) {
                // // this.my_like = true;
                this.e[i].is_collect = 1;
                this.$message.success({
                  duration: 2000,
                  message: "收藏成功!",
                });
            //   }
            });
          } else {
            // this.my_like = false;
            this.$message.error({
              duration: 2000,
              message: "本题已收藏!",
            });
          }
          break;
      }
      // console.log("收藏el====", el);
    },
    // 取消收藏
    delcollect(i,t) {

        switch (t) {
        case 'a':
            console.log(this.a)
          if (this.a[i].is_collect == 1) {
            let data = {
              chapter_category: this.a[i].two_chaptercate_id,
              ex_id: this.a[i].id,
              three_category_id:this.a[i].chapter_category_id,
              qtype: this.a[i].qtype,
            };
            api.del_collect(data).then((res) => {
              // console.log("收藏", res);
              if (parseInt(res.data.code) == 1) {
                // // this.my_like = true;
                this.a[i].is_collect = 0;
                this.$message.success({
                  duration: 2000,
                  message: "取消收藏成功!",
                });
              }
            });
          } else {
            // this.my_like = false;
            this.$message.error({
              duration: 2000,
              message: "本题未收藏!",
            });
          }
          break;
          case 'b':
          if (this.b[i].is_collect == 1) {
            let data = {
              chapter_category: this.b[i].two_chaptercate_id,
              ex_id: this.b[i].id,
              three_category_id:this.a[i].chapter_category_id,
              qtype: this.b[i].qtype,
            };
            api.del_collect(data).then((res) => {
              // console.log("收藏", res);
              if (parseInt(res.data.code) == 1) {
                // // this.my_like = true;
                this.b[i].is_collect = 0;
                this.$message.success({
                  duration: 2000,
                  message: "取消收藏成功!",
                });
              }
            });
          } else {
            // this.my_like = false;
            this.$message.error({
              duration: 2000,
              message: "本题未收藏!",
            });
          }
          break;
          case 'c':
          if (this.c[i].is_collect == 1) {
            let data = {
              chapter_category: this.c[i].two_chaptercate_id,
              ex_id: this.c[i].id,
              three_category_id:this.a[i].chapter_category_id,
              qtype: this.c[i].qtype,
            };
            api.del_collect(data).then((res) => {
              // console.log("收藏", res);
              if (parseInt(res.data.code) == 1) {
                // // this.my_like = true;
                this.c[i].is_collect = 0;
                this.$message.success({
                  duration: 2000,
                  message: "取消收藏成功!",
                });
              }
            });
          } else {
            // this.my_like = false;
            this.$message.error({
              duration: 2000,
              message: "本题未收藏!",
            });
          }
          break;
          case 'd':
          if (this.d[i].is_collect == 1) {
            let data = {
              chapter_category:this.d[i].two_chaptercate_id,
              ex_id:this.d[i].id,
              three_category_id:this.a[i].chapter_category_id,
              qtype:this.d[i].qtype,
            };
            api.del_collect(data).then((res) => {
              // console.log("收藏", res);
              if (parseInt(res.data.code) == 1) {
                // // this.my_like = true;
               this.d[i].is_collect = 0;
                this.$message.success({
                  duration: 2000,
                  message: "取消收藏成功!",
                });
              }
            });
          } else {
            // this.my_like = false;
            this.$message.error({
              duration: 2000,
              message: "本题未收藏!",
            });
          }
          break;
          case 'e':
          if (this.e[i].is_collect == 1) {
            let data = {
              chapter_category: this.e[i].two_chaptercate_id,
              ex_id: this.e[i].id,
              three_category_id:this.a[i].chapter_category_id,
              qtype: this.e[i].qtype,
            };
            api.del_collect(data).then((res) => {
              // console.log("收藏", res);
              if (parseInt(res.data.code) == 1) {
                // // this.my_like = true;
                this.e[i].is_collect = 0;
                this.$message.success({
                  duration: 2000,
                  message: "取消收藏成功!",
                });
              }
            });
          } else {
            // this.my_like = false;
            this.$message.error({
              duration: 2000,
              message: "本题未收藏!",
            });
          }
          break;
      }
    },
    // 试题纠错弹窗
    errorCorrection(item) {
      console.log(item);
      this.show_wrongmask = true; // 显示纠错弹窗
      this.topic_id = item.id;
      this.topic_type = item.qtype;
      // console.log("item=====", item);
    },
    // 提交纠错
    submitError() {
      if (this.suggest.length == 0) {
        this.$message.error({
          message: "描述不能为空!",
          duration: 2000,
        });
        return false;
      } else {
        let data = {
          chapter_category: this.$route.params.chapter_id, // 章节id
          ex_id: this.topic_id, // 题目id
          qtype: this.topic_type, // 题目类型
          suggest: this.suggest, // 纠错描述
        };
        api.correction_add(this.qs.stringify(data)).then((res) => {
          if (res.data.code == 1) {
            this.$message.success({
              message: res.data.msg,
              duration: 2000,
            });
            this.show_wrongmask = false; // 关闭纠错弹窗
          }
          // console.log("纠错======", res);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
#app {
  // background: #f5f5f5;
  // 内容区
  .content_box {
    // position: relative;
    width: 100%;
    // height: 100vh;
    background: #f5f5f5;
    // padding-bottom: 300px;
    overflow: hidden;
    .loading_box {
      width: 1200px;
      height: 872px;
      // height: 100vh;
      background: #fff;
      margin: 10px auto 0;
      overflow: hidden;
      display: flex;
      flex-flow: column;
      align-items: center;
      img {
        width: 44px;
        height: 40px;
        margin: 227px 0 18px;
      }
      p {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
    .content {
      position: relative;
      width: 1200px;
      margin: auto;
      overflow: hidden;
      // 标题
      .title_box {
        // position: fixed;
        // top: 0;
        width: 1200px;
        height: 76px;
        background: #fff;
        margin: 16px 0 10px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #222222;
        line-height: 76px;
        text-align: center;
        z-index: 99;
      }
      #scroll_box {
        width: 100%;
        height: 195px;
        display: none;
        // background: pink;
      }
      // 试题
      .test_questions_box {
        // transform: scale(1, 1);
        width: 100%;
        display: flex;
        justify-content: space-between;
        overflow: auto; /*关键点*/
        // 左边试题
        .left_topic_box {
          width: 880px;
          .topic_box {
            // 规则
            .rule_box {
              width: 839px;
              padding: 20px 20px;
              background: #ebf2ff;
              border-radius: 1px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #3e7eff;
            }
            .topic_el {
              width: 839px;
              height: 100%;
              background: #fff;
              margin: 10px 0;
              padding: 30px 20px 20px 20px;
              // 题目
              .topic_title_box {
                display: flex;
                font-size: 16px;
                font-family: PingFang SC;
                // font-weight: 400;
                color: #363636;
                margin-bottom: 25px;
                .topic {
                  em {
                    font-style: normal;
                    font-weight: 600;
                  }
                  i {
                    font-style: normal;
                    margin: 0 5px;
                    font-weight: 600;
                  }
                  img {
                    width: 650px;
                    height: 300px;
                  }
                }
              }
              // 答案解析
              .analysis_card_box {
                width: 808px;
                height: 100%;
                background: #f5f5f5;
                margin: 24px auto 20px;
                padding: 0 16px;
                .analysis_card_top {
                  // width: 100%;
                  height: 60px;
                  border-bottom: 1px dashed #dddddd;
                  display: flex;
                  align-items: center;
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  div:nth-child(1) {
                    min-width: 92px;
                    color: #72b852;
                    margin-right: 57px;
                  }
                }
                .analysis_card_down {
                  display: flex;
                  justify-content: space-between;
                  padding: 18px 0;
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  i {
                    font-style: normal;
                    min-width: 70px;
                    margin-right: 10px;
                  }
                }
              }
              // 选项
              .option_box {
                .option_el {
                  margin-bottom: 6px;
                  .option {
                    display: flex;
                    align-items: center;
                    padding: 5px 0;
                    cursor: pointer;
                    button {
                      min-width: 26px;
                      height: 26px;
                      border: 1px solid #989898;
                      background: #fff;
                      border-radius: 50%;
                      text-align: center;
                      font-style: normal;
                      margin: 0 14px 0 6px;
                    }
                    .blue {
                      width: 26px;
                      height: 26px;
                      background: #3e7eff;
                      border-radius: 50%;
                      color: #ffffff;
                      border: none;
                    }
                  }
                  // 富文本
                  .option_rich_text {
                    display: flex;
                    align-items: flex-start;
                  }
                  // 选项hover时
                  .hover:hover {
                    padding: 5px 0;
                    background: #f7f9ff;
                    border-radius: 4px;
                  }
                  // 纯图片
                  .option_img {
                    position: relative;
                    .option_left {
                      width: 686px;
                      // height: 300px;
                      display: flex;
                      align-items: flex-start;
                      overflow: hidden;
                      padding: 10px 0;
                      overflow: hidden;
                      cursor: pointer;
                      img {
                        max-width: 650px;
                        max-height: 300px;
                      }
                    }
                    .option_right {
                      position: absolute;
                      right: 0;
                      bottom: 10px;
                      min-width: 85px;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      font-size: 15px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #3e7eff;
                      img {
                        width: 16px;
                        height: 16px;
                      }
                    }
                    // 选项hover时
                    .option_left:hover {
                      // padding: 5px 0;
                      background: #f7f9ff;
                      border-radius: 4px;
                    }
                  }
                }
              }
              // 材料分析题
              .simple_box {
                color: #666;
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 500;

                em {
                  font-style: normal;
                  font-size: 18px;
                  font-family: PingFang SC;
                  font-weight: 600;
                  color: #222222;
                }
              }
              .problem_box {
                margin: 36px 0 15px;
                div {
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #222222;
                }
                img {
                  max-width: 829px;
                }
              }

              // 收藏
              .coolect_box {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                .coolect_left,
                .coolect_right {
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  img {
                    width: 15px;
                    height: 14px;
                    margin-right: 4px;
                  }
                }
                .no_collect {
                  color: #3e7eff;
                }
                .collect_line {
                  width: 1px;
                  height: 17px;
                  background: #e5e5e5;
                  border-radius: 1px;
                  margin: 0 20px;
                }
              }
            }
          }
          // 配伍题
          .peiwu_topic_box {
            .topic_el {
              width: 840px;
              // height: 50px;
              // background: pink;
              padding: 30px 20px 20px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #363636;
              // 题目
              .peiwu_title_box {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #363636;
                .title_el {
                  display: flex;
                  margin-top: 16px;
                  i {
                    // display: block;
                    // min-width: 16px;
                    font-style: normal;
                  }
                  // 纯图片
                  .option_img {
                    position: relative;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    .option_left {
                      width: 680px;
                      padding: 10px 0 10px 6px;
                      display: flex;
                      align-items: flex-start;
                      img {
                        max-width: 650px;
                        max-height: 300px;
                      }
                    }
                    .option_left:hover {
                      background: #f7f9ff;
                      border-radius: 4px;
                    }
                    .option_right {
                      position: absolute;
                      right: 0;
                      bottom: 10px;
                      display: flex;
                      align-items: center;
                      font-size: 15px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #3e7eff;
                      img {
                        width: 16px;
                        height: 16px;
                        margin-right: 9px;
                      }
                    }
                  }
                }
                // .title_el:hover {
                //   background: #f7f9ff;
                //   border-radius: 4px;

                // }
              }
              // 下方切换
              .toogle_box {
                width: 100%;
                margin-top: 40px;
                margin-bottom: 20px;
                .toogle_top {
                  display: flex;
                  overflow: hidden;
                  li {
                    // div:nth-child(1) {
                    width: 60px;
                    height: 30px;
                    border-top: 3px solid #78a4ff;
                    border-bottom: 3px solid #78a4ff;
                    border-left: 3px solid #78a4ff;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #729cff;
                    line-height: 30px;
                    text-align: center;
                  }
                  .card_style {
                    background: #3e7eff;
                    color: #fff;
                  }
                  li:last-child {
                    border-right: 3px solid #78a4ff;
                  }
                }
                .toogle_down {
                  width: 840px;
                  // height: 280px;
                  .toogle_down_el {
                    padding: 32px 14px 32px 26px;
                    border: 2px solid #eeeeee;
                    // 标题
                    .small_topic_title {
                      display: flex;
                      margin-bottom: 30px;
                      em {
                        font-style: normal;
                      }
                    }
                    .small_topic_title:nth-child(3) {
                      display: flex;
                      flex-flow: column;
                      img {
                        max-width: 800px;
                      }
                    }
                    // 选项
                    .option_box {
                      .option_el {
                        margin-bottom: 6px;
                        .option {
                          display: flex;
                          align-items: center;
                          padding: 5px 0;
                          cursor: pointer;
                          button {
                            min-width: 26px;
                            height: 26px;
                            border: 1px solid #989898;
                            background: #fff;
                            border-radius: 50%;
                            text-align: center;
                            font-style: normal;
                            margin: 0 14px 0 6px;
                          }
                          .blue {
                            width: 26px;
                            height: 26px;
                            background: #3e7eff;
                            border-radius: 50%;
                            color: #ffffff;
                            border: none;
                          }
                        }
                        // 富文本
                        .option_rich_text {
                          display: flex;
                          align-items: flex-start;
                        }
                        // 选项hover时
                        .hover:hover {
                          padding: 5px 0;
                          background: #f7f9ff;
                          border-radius: 4px;
                        }
                        // 纯图片
                        .option_img {
                          position: relative;
                          .option_left {
                            // width: 686px;
                            width: 840px;
                            // height: 300px;
                            display: flex;
                            align-items: flex-start;
                            overflow: hidden;
                            padding: 10px 0;
                            overflow: hidden;
                            cursor: pointer;
                            img {
                              max-width: 650px;
                              max-height: 300px;
                            }
                          }
                          // 选项hover时
                          .option_left:hover {
                            // padding: 5px 0;
                            background: #f7f9ff;
                            border-radius: 4px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        #white {
          width: 879px;
          height: 100%;
          background: pink;
          z-index: -1;
          display: none;
        }
        /*滚动条整体部分,必须要设置*/
        ::-webkit-scrollbar {
          width: 6px;
          background: #eeeeee;
          border-radius: 3px;
        }
        /*滚动条的轨道*/
        ::-webkit-scrollbar-track {
          background-color: #eeeeee;
          border-radius: 3px;
        }
        /*滚动条的滑块按钮*/
        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #ccc;
        }

        // 右边答题卡
        .right_answer_sheet {
          width: 310px;
          // height: 675px;
          height: 100%;
          background: #fff;
          overflow-y: auto;
          /*滚动条整体部分,必须要设置*/
          ::-webkit-scrollbar {
            width: 6px;
            background: #eeeeee;
            border-radius: 3px;
          }
          /*滚动条的轨道*/
          ::-webkit-scrollbar-track {
            background-color: #eeeeee;
            border-radius: 3px;
          }
          /*滚动条的滑块按钮*/
          ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #ccc;
          }

          .card_content {
            width: 100%;
            margin: auto;
            // 用时
            .card_top {
              height: 50px;
              border-bottom: 1px solid #f5f5f5;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 0 16px;
              .card_top_left {
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #222222;
                display: flex;
                align-items: center;
                div {
                  width: 3px;
                  height: 16px;
                  background: #d5362f;
                  border-radius: 2px;
                  margin-right: 9px;
                }
              }
              .card_top_right {
                display: flex;
                align-items: center;
                img {
                  width: 16px;
                  height: 17px;
                }
                p:nth-child(2) {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  margin: 0 3px 0 7px;
                }
                p:nth-child(3) {
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #d5362f;
                }
              }
            }
            // 题号
            .num_box {
              // height: 28.75rem;
              // overflow-y: auto;
              margin-left: 16px;
              margin-top: 20px;
              .num_el_box {
                .card_rules_box {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  text-decoration: underline;
                  cursor: pointer;
                  color: #3e7eff;
                }
                .no_style {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  text-decoration: none;
                }
                ul {
                  // width: 278px;
                  margin: 17px auto 0;
                  display: flex;
                  flex-wrap: wrap;
                  overflow-x: hidden;
                  // justify-content: space-between;
                  li {
                    width: 30px;
                    height: 30px;
                    border: 1px solid #e5e7eb;
                    border-radius: 4px;
                    margin-right: 11px;
                    margin-bottom: 14px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 30px;
                    text-align: center;
                    cursor: pointer;
                  }
                  .isCurrent {
                    background: #ccccd5;
                    color: #fff;
                  }
                  .done {
                    border: 1px solid #3e7eff;
                    color: #3e7eff;
                  }
                }
                .peiwu_nums_box {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  margin-top: 17px;
                  .peiwu {
                    width: auto;
                    display: flex;
                    div {
                      width: 30px;
                      height: 30px;
                      border: 1px solid #e5e7eb;
                      border-radius: 4px;
                      margin-right: 11px;
                      margin-bottom: 14px;
                      font-size: 14px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #333333;
                      line-height: 30px;
                      text-align: center;
                      cursor: pointer;
                    }
                    .isCurrent {
                      background: #ccccd5;
                      color: #fff;
                    }
                    .done {
                      border: 1px solid #3e7eff;
                      color: #3e7eff;
                    }
                  }
                }
              }
            }
            // 保存/交卷
            .btn_box {
              // width: 100%;
              height: 164px;
              border-top: 1px dashed #f5f5f5;
              margin: 0 16px;
              ul {
                width: 197px;
                display: flex;
                justify-content: space-between;
                margin: 18px auto;
                li {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #333333;
                  display: flex;
                  align-items: center;
                  span {
                    display: block;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    border: 1px solid #3e7eff;
                    margin-left: 9px;
                  }
                }
                li:nth-child(2) {
                  span {
                    border: 1px solid #e5e7eb;
                  }
                }
                li:nth-child(3) {
                  span {
                    border: none;
                    background: #dddddd;
                  }
                }
              }
              button:nth-child(2) {
                width: 278px;
                height: 46px;
                background: #eeeeee;
                border-radius: 6px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #333333;
              }
              button:nth-child(3) {
                width: 278px;
                height: 46px;
                background: #3e7eff;
                border-radius: 6px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #fff;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }

  // 试题纠错弹窗
  .wrongmask_box {
    position: fixed;
    top: 20%;
    left: 0;
    right: 0;
    width: 540px;
    height: 423px;
    margin: auto;
    z-index: 999;
    .mask_box {
      position: relative;
      width: 540px;
      height: 423px;
      border: 1px solid #dddddd;
      background: #fff;
      box-shadow: 0px 12px 24px 0px rgba(0, 13, 38, 0.1);
      border-radius: 8px;
      overflow: hidden;
      img {
        position: absolute;
        top: 11px;
        right: 11px;
      }
      .wrong_content {
        width: 460px;
        margin: 31px auto 0;
        .wrong_title {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .wrong_text {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;
          // margin-top: 14px;
          margin: 4px 0 21px;
        }
        .wrong_text2 {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-bottom: 11px;
        }
        .wrong_ipt_box {
          position: relative;
          textarea {
            width: 426px;
            height: 178px;
            border: 1px solid #dddddd;
            border-radius: 2px;
            resize: none;
            padding: 17px 17px 25px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          p {
            position: absolute;
            right: 11px;
            bottom: 11px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
        .submit_box {
          overflow: hidden;
          width: 200px;
          margin: 20px auto;
          button:nth-child(1) {
            width: 90px;
            height: 30px;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #3e7eff;
            border: 1px solid #3e7eff;
            background: #fff;
            margin-right: 20px;
          }
          button:nth-child(2) {
            width: 90px;
            height: 30px;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #fff;
            border: 1px solid #3e7eff;
            background: #3e7eff;
          }
        }
      }
    }
  }
}
// 选项卡
::v-deep .option_box {
  width: 100%;
  // height: 1000px;
  // background: pink;
  .option_content_box {
    // width: 800px;
    display: flex;
    flex-flow: column;
    align-items: center;
    // padding: 0 30px;
    // 题目
    .option_content {
      width: 100%;
      min-height: 398px;
      //   background: #ffffff;
      border-radius: 12px;
      // 单选/多选
      .option_name {
        // width: 100%;
        justify-content: space-between;
        // padding: 0 40px;
        // margin: 16px 0 38px;
        p {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #222222;
        }
        // 收藏
        .collect_box {
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          img {
            width: 16px;
            // height: 14px;
            margin-right: 4px;
          }
        }
        // 取消收藏
        .no_collect {
          color: #3e7eff;
        }
      }
      // 配伍题问题
      .topic_box {
        .option_box {
          display: flex;
          margin-top: -17px;
          li {
            display: flex;
            margin-right: 60px;
            p {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }
        }
      }
      // 问题标题
      .question_title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
        margin-bottom: 25px;
      }
      // 材料分析题问题
      .material_problem_box {
        // 简答题文案
        .brief_answer {
          margin-top: 29px;
          display: flex;
          align-items: center;
          p {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #222222;
          }
          span {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666;
          }
        }
        // 问题
        .problem_content_box {
          margin: 37px 0 69px;
          div {
            p {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }
        }
      }
      // 单选选项组
      .radio_box {
        ul {
          .dis {
            pointer-events: none;
          }
          li {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            .left {
              display: flex;
              align-items: center;
              width: 100%;
              max-height: 300px;
              overflow: hidden;
              padding: 15px;
              cursor: pointer;
              button {
                min-width: 26px;
                height: 26px;
                border: 1px solid #989898;
                border-radius: 50%;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
                text-align: center;
                // line-height: 26px;
                margin-right: 14px;
                background: #fff;
              }
              p {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
              }
              img {
                max-width: 800px;
                max-height: 300px;
                // margin-bottom: 9px;
              }
              .yes {
                background: #72b852;
                color: #ffffff;
                border: none;
              }
              .wrong {
                background: #ee3d2b;
                color: #ffffff;
                border: none;
              }
            }
            .notclick {
              pointer-events: none;
            }
            .left:hover {
              background: #f7f9ff;
            }
            .right {
              position: absolute;
              right: 15px;
              bottom: 20px;
              display: flex;
              // align-items: center;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #3e7eff;
              margin-left: 51px;
              // margin-bottom: 9px;
              line-height: 16px;
              img {
                width: 16px;
                height: 16px;
                margin-right: 9px;
              }
            }
          }
          li:last-child {
            margin-bottom: 0;
          }
        }
      }
      // 多选题组
      .check_box {
        ul {
          li {
            .left {
              button {
                border-radius: 4px;
                cursor: pointer;
              }
              .check {
                background: #3e7eff;
                color: #fff;
                border: none;
              }
            }
          }
        }
      }

      // 配伍题/案例选择
      .compatibility_box {
        margin-top: 39px;
        /deep/.el-pagination {
          padding: 0;
          .el-pager {
            .number {
              width: 60px;
              height: 30px;
              border: 2px solid #78a4ff;
              border-radius: 0;
              margin: 0;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #729cff;
              background: #fff;
            }
            .active {
              border: none;
              background: #3e7eff;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #ffffff;
            }
          }
        }
        // 小题
        .small_topic_box {
          width: 1102px;
          min-height: 100px;
          border: 2px solid #eeeeee;
          padding-left: 18px;
          .topic_title {
            display: flex;
            margin: 30px 0;
          }
        }
      }
    }
    //  上一题/下一题
    .cut_topic {
      position: fixed;
      bottom: 0;
      width: 906px;
      height: 66px;
      background: #ffffff;
      padding: 0 147px;
      box-shadow: 0px -4px 20px 0px rgba(0, 13, 40, 0.03);
      border-radius: 12px 12px 0px 0px;
      z-index: 1;
      cursor: pointer;
      // margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-left: -40px;
      .prev_box {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
        cursor: pointer;
        background: #fff;
        i {
          margin-right: 30px;
          color: #666;
        }
      }
      .look_answer {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3e7eff;
        display: flex;
        align-items: center;
        img {
          width: 22px;
          height: 26px;
          margin-right: 10px;
        }
        .close_light {
          color: #222;
        }
      }
    }
  }
}
::v-deep .option_box .option_content_box .option_content .radio_box_p ul {
  display: flex;
}
::v-deep
  .option_box
  .option_content_box
  .option_content
  .radio_box_p
  ul
  li
  .left {
  display: flex;
  align-items: center;
  width: 973px;
  max-height: 300px;
  overflow: hidden;
  padding-left: 6px;
  cursor: pointer;
}
::v-deep
  .option_box
  .option_content_box
  .option_content
  .radio_box_p
  ul
  li
  .left
  button {
  min-width: 26px;
  height: 26px;
  border: 1px solid #989898;
  border-radius: 50%;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #222222;
  text-align: center;
  margin-right: 14px;
  background: #fff;
}
::v-deep
  .option_box
  .option_content_box
  .option_content
  .radio_box_p
  ul
  li
  .left
  p {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #222222;
}
//配伍题问题
::v-deep .radio_c_c .c_box {
  padding: 30px 20px;
  margin-bottom: 20px;
  border-radius: 12px;
  background: #f8f8f8;
}
::v-deep .radio_c_c .analysis_box {
  margin: 0;
}
::v-deep .radio_c_c .analysis_box,
::v-deep .radio_c_c .top {
  background: transparent !important;
}
::v-deep .radio_c_c ul {
  display: flex;
  margin: 30px 0 0;
}
::v-deep .radio_c_c ul .red {
  color: #ee3d2b;
}
::v-deep .radio_c_c ul .red i {
  color: #fff !important;
  background: #ee3d2b !important;
  border: none !important;
}
::v-deep .radio_c_c ul .yes {
  color: #72b852;
}
::v-deep .radio_c_c ul .yes i {
  color: #fff !important;
  background: #72b852 !important;
  border: none !important;
}
::v-deep .radio_c_c ul li {
  width: 100px;
  height: 40px;
  align-items: center;
  display: flex;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #222222;
  margin-right: 36px;
  cursor: pointer;
  font-size: 18px;
}
::v-deep .radio_c_c ul li i {
  width: 22px;
  height: 22px;
  border: 1px solid #989898;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
  margin-left: 13px;
  margin-right: 12px;
}
::v-deep .radio_box_pcl {
  width: auto !important;
  border-radius: 15px;
  margin: 30px 0 0 !important;
  padding: 30px 20px;
  overflow: hidden;
}
::v-deep .analysis_box {
  width: auto !important;
}

//   答案解析
::v-deep .radio_box_pcl,
::v-deep .radio_c_c,
::v-deep .analysis_box {
  width: 1120px;
  margin: 54px auto 70px;
  background: #f5f5f5;
  // 单选/多选/配伍/材料选择
  .top {
    height: 60px;
    background: #f5f5f5;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      span {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #72b852;
        img {
          width: 14px;
          height: 14px;
        }
        .dui_icon {
          width: 17px;
          height: 12px;
        }
      }
      span:nth-child(1) {
        margin-right: 45px;
      }
      .user_answer {
        color: #72b852;
      }
      .wrong_answer {
        color: #ee3d2b;
      }
    }
    .right {
      display: flex;
      align-items: center;
      img {
        width: 14px;
        height: 14px;
        margin-right: 6px;
      }
      p {
        cursor: pointer;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #777777;
      }
    }
  }
  .down {
    width: 1087px;
    // min-height: 80px;
    background: #f5f5f5;
    border-top: 1px dashed #dddddd;
    margin: auto;
    .down_content {
      display: flex;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      padding: 18px 0;
      div:nth-child(1) {
        min-width: 70px;
        margin-right: 10px;
      }
    }
  }
  // 材料分析题解析
  .material_analysis_box {
    position: relative;
    width: 100%;
    background: #fff;
    overflow: hidden;
    // 答案解析展开状态
    .put_away_box {
      width: 84px;
      height: 36px;
      // background: url();
      background: #ff9302;
      border-radius: 6px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // display: none;
      z-index: -1;
      p {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      img {
        width: 14px;
        height: 14px;
      }
    }
    // 答案解析展开状态
    .open_box {
      position: absolute;
      top: 1px;
      z-index: 9;
      width: 84px;
      height: 45px;
      background: url("../../assets/img/shouqi1.png") no-repeat;
      background-size: 100px 45px;
      padding: 0 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .card_box {
      width: 1088px;
      // min-height: 251px;
      padding: 0 16px;
      background: #f5f5f5;
      margin-top: 6px;
      overflow: hidden;
      p {
        margin: 52px 0 19px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
::v-deep .radio_c_c {
  background: #fff !important;
  margin:0 auto 70px !important;
}
::v-deep .compatimy {
  margin-top: -18px !important;
}
::v-deep .compatimy ul li {
  // margin-bottom: 15px !important;
}
// 单选选项组
::v-deep .radio_box_pcl {
  ul {
    .dis {
      pointer-events: none;
    }
    li {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        width: 100%;
        max-height: 300px;
        overflow: hidden;
        padding: 15px;
        cursor: pointer;
        button {
          min-width: 26px;
          height: 26px;
          border: 1px solid #989898;
          border-radius: 50%;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
          text-align: center;
          // line-height: 26px;
          margin-right: 14px;
          background: #fff;
        }
        p {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
        img {
          max-width: 800px;
          max-height: 300px;
          margin-bottom: 9px;
        }
        .yes {
          background: #72b852;
          color: #ffffff;
          border: none;
        }
        .wrong {
          background: #ee3d2b;
          color: #ffffff;
          border: none;
        }
      }
      .notclick {
        pointer-events: none;
      }
      .left:hover {
        background: #f7f9ff;
      }
      .right {
        position: absolute;
        right: 15px;
        bottom: 20px;
        display: flex;
        // align-items: center;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3e7eff;
        margin-left: 51px;
        // margin-bottom: 9px;
        line-height: 16px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 9px;
        }
      }
    }
    li:last-child {
      margin-bottom: 0;
    }
  }
}
::v-deep .d_x_x_z {
  background: #3e7eff ;
  color: #fff ;
  border: none ;
}
::v-deep .YES {
  padding: 8px 50px !important;
  border-radius: 6px !important;
  margin: 30px 0;
}
.card {
  background: #fff;
  padding: 30px 20px 20px;
  margin-bottom: 10px;
}
.bt {
  padding: 20px;
  color: #3e7eff;
  background: #ebf2ff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

::v-deep .radio_c_c {
  width: auto !important;
}
::v-deep .option_content .option_name p {
  font-size: 16px !important;
  font-weight: 400;
}
// 试题纠错弹窗
.wrongmask_box {
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  width: 540px;
  height: 423px;
  margin: auto;
  z-index: 999;
  .mask_box {
    position: relative;
    width: 540px;
    height: 423px;
    border: 1px solid #dddddd;
    background: #fff;
    box-shadow: 0px 12px 24px 0px rgba(0, 13, 38, 0.1);
    border-radius: 8px;
    overflow: hidden;
    img {
      position: absolute;
      top: 11px;
      right: 11px;
    }
    .wrong_content {
      width: 460px;
      margin: 31px auto 0;
      .wrong_title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .wrong_text {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
        // margin-top: 14px;
        margin: 4px 0 21px;
      }
      .wrong_text2 {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-bottom: 11px;
      }
      .wrong_ipt_box {
        position: relative;
        textarea {
          width: 426px;
          height: 178px;
          border: 1px solid #dddddd;
          border-radius: 2px;
          resize: none;
          padding: 17px 17px 25px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        p {
          position: absolute;
          right: 11px;
          bottom: 11px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
      .submit_box {
        overflow: hidden;
        width: 200px;
        margin: 20px auto;
        button:nth-child(1) {
          width: 90px;
          height: 30px;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #3e7eff;
          border: 1px solid #3e7eff;
          background: #fff;
          margin-right: 20px;
        }
        button:nth-child(2) {
          width: 90px;
          height: 30px;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #fff;
          border: 1px solid #3e7eff;
          background: #3e7eff;
        }
      }
    }
  }
}
::v-deep .question_title{
  padding: 0 15px;
}
</style>
