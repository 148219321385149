var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('Tabs'),_c('div',{staticClass:"content_box"},[(_vm.loading == true)?_c('div',{staticClass:"loading_box"},[_c('img',{attrs:{"src":require("../../../../assets/img/black_small.gif"),"alt":""}}),_c('p',[_vm._v("正在努力布置考场，请耐心等待~")])]):_vm._e(),(_vm.loading == false)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"title_box",attrs:{"id":"title"}},[_vm._v(_vm._s(_vm.title))]),_c('div',{attrs:{"id":"scroll_box"}}),_c('div',{staticClass:"test_questions_box"},[_c('div',{staticClass:"left_topic_box"},[_c('AllTest',{attrs:{"data":_vm.paper_arr},on:{"isTNum":_vm.isTNum}})],1),_c('div',{staticClass:"right_answer_sheet",staticStyle:{"height":"515px"},attrs:{"id":"card"}},[_c('div',{staticClass:"card_content",staticStyle:{"height":"450px","overflow-y":"auto","background":"#fff"}},[_c('div',{staticClass:"card_top"},[_vm._m(0),_c('div',{staticClass:"card_top_right"},[_c('img',{attrs:{"src":require("../../../../assets/img/yongshi.png"),"alt":""}}),_c('p',[_vm._v("剩余")]),_c('p',[_vm._v(_vm._s((_vm.h + ": " + _vm.m + ": " + _vm.s)))])])]),_c('div',{staticClass:"num_box"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.paper_arr.filter(function (res) { return res.qtype == 1; }).length > 0),expression:"paper_arr.filter((res) => res.qtype == 1).length > 0"}],staticClass:"num_el_box"},[_c('a',{staticClass:"card_rules_box",attrs:{"href":"#radio"}},[_vm._v(" 单项选择题（共"+_vm._s(_vm.paper_arr.filter(function (res) { return res.qtype == 1; }).length)+"题） ")]),_c('ul',_vm._l((_vm.paper_arr.filter(
                      function (res) { return res.qtype == 1; }
                    )),function(item,index){return _c('li',{key:index + 'num1',class:[
  _vm.isT_num == item.isNum + 1 ? 'isCurrent' : '',
  _vm.paper[item.isNum + 1] && _vm.paper[item.isNum + 1] != ''
    ? 'done'
    : '' ],on:{"click":function($event){return _vm.goTopic(item.isNum + 1)}}},[_c('a',{attrs:{"href":("#" + (item.isNum + 1))}},[_vm._v(" "+_vm._s(item.isNum + 1)+" ")])])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.paper_arr.filter(function (res) { return res.qtype == 2; }).length > 0),expression:"paper_arr.filter((res) => res.qtype == 2).length > 0"}],staticClass:"num_el_box"},[_c('a',{staticClass:"card_rules_box",attrs:{"href":"#peiwu"}},[_vm._v(" 配伍题（共"+_vm._s(_vm.paper_arr.filter(function (res) { return res.qtype == 2; }).length)+"题） ")]),_c('div',{staticClass:"peiwu_nums_box"},_vm._l((_vm.paper_arr.filter(
                      function (res) { return res.qtype == 2; }
                    )),function(item,index){return _c('div',{key:index + 'num1',staticClass:"peiwu"},_vm._l((item.qu_list),function(el,index){return _c('div',{key:index + 'qu_list',class:[
                          _vm.isT_num == el.isNum + 1 ? 'isCurrent' : '',
                          _vm.paper[el.isNum + 1] && _vm.paper[el.isNum + 1] != ''
                            ? 'done'
                            : '' ],on:{"click":function($event){return _vm.goTopic(el.isNum + 1)}}},[_c('a',{attrs:{"href":("#" + (el.isNum + 1))}},[_vm._v(" "+_vm._s(el.isNum + 1)+" ")])])}),0)}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.paper_arr.filter(function (res) { return res.qtype == 3; }).length > 0),expression:"paper_arr.filter((res) => res.qtype == 3).length > 0"}],staticClass:"num_el_box"},[_c('a',{staticClass:"card_rules_box",attrs:{"href":"#stuff_case"}},[_vm._v(" 材料分析选择题（共"+_vm._s(_vm.paper_arr.filter(function (res) { return res.qtype == 3; }).length)+"题） ")]),_c('div',{staticClass:"peiwu_nums_box"},_vm._l((_vm.paper_arr.filter(
                      function (res) { return res.qtype == 3; }
                    )),function(item,index){return _c('div',{key:index + 'num1',staticClass:"peiwu"},_vm._l((item.qu_list),function(el,index){return _c('div',{key:index + 'qu_list',class:[
                          _vm.isT_num == el.isNum + 1 ? 'isCurrent' : '',
                          _vm.paper[el.isNum + 1] && _vm.paper[el.isNum + 1] != ''
                            ? 'done'
                            : '' ],on:{"click":function($event){return _vm.goTopic(el.isNum + 1)}}},[_c('a',{attrs:{"href":("#" + (el.isNum + 1))}},[_vm._v(" "+_vm._s(el.isNum + 1)+" ")])])}),0)}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.paper_arr.filter(function (res) { return res.qtype == 4; }).length > 0),expression:"paper_arr.filter((res) => res.qtype == 4).length > 0"}],staticClass:"num_el_box"},[_c('a',{staticClass:"card_rules_box",attrs:{"href":"#checkbox"}},[_vm._v(" 多项选择题（共"+_vm._s(_vm.paper_arr.filter(function (res) { return res.qtype == 4; }).length)+"题） ")]),_c('ul',_vm._l((_vm.paper_arr.filter(
                      function (res) { return res.qtype == 4; }
                    )),function(item,index){return _c('li',{key:index + 'num1',class:[
  _vm.isT_num == item.isNum + 1 ? 'isCurrent' : '',
  _vm.paper[item.isNum + 1] && _vm.paper[item.isNum + 1] != ''
    ? 'done'
    : '' ],on:{"click":function($event){return _vm.goTopic(item.isNum + 1)}}},[_c('a',{attrs:{"href":("#" + (item.isNum + 1))}},[_vm._v(" "+_vm._s(item.isNum + 1)+" ")])])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.paper_arr.filter(function (res) { return res.qtype == 5; }).length > 0),expression:"paper_arr.filter((res) => res.qtype == 5).length > 0"}],staticClass:"num_el_box"},[_c('a',{staticClass:"card_rules_box",attrs:{"href":"#stuff"}},[_vm._v("材料分析题（不予评分）")]),_c('ul',_vm._l((_vm.paper_arr.filter(
                      function (res) { return res.qtype == 5; }
                    )),function(item,index){return _c('li',{key:index + 'num1',class:[_vm.topic_num == item.isNum ? 'isCurrent' : ''],on:{"click":function($event){return _vm.goTopic(item.isNum + 1)}}},[_c('a',{attrs:{"href":("#" + (item.isNum + 1))}},[_vm._v(" "+_vm._s(item.isNum + 1)+" ")])])}),0)])])]),_c('div',{staticClass:"btn_box"},[_vm._m(1),_c('button',{attrs:{"type":"button"},on:{"click":_vm.save}},[_vm._v("保存进度，下次继续")]),_c('button',{attrs:{"type":"button"},on:{"click":_vm.handOver}},[_vm._v("交卷")])])])])]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_wrongmask == true),expression:"show_wrongmask == true"}],staticClass:"wrongmask_box"},[_c('div',{staticClass:"mask_box"},[_c('img',{attrs:{"src":require("../../../../assets/img/close.png"),"alt":""},on:{"click":function($event){_vm.show_wrongmask = false}}}),_c('div',{staticClass:"wrong_content"},[_c('div',{staticClass:"wrong_title"},[_vm._v("试题纠错")]),_c('div',{staticClass:"wrong_text"},[_vm._v("非常感谢您向我们提出错误，我们将尽力为您提供最优质的服务")]),_c('div',{staticClass:"wrong_text2"},[_vm._v("错误描述")]),_c('div',{staticClass:"wrong_ipt_box"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.suggest),expression:"suggest"}],attrs:{"name":"","id":"","cols":"30","rows":"10","maxlength":"300"},domProps:{"value":(_vm.suggest)},on:{"input":function($event){if($event.target.composing){ return; }_vm.suggest=$event.target.value}}}),_c('p',[_vm._v(_vm._s(_vm.suggest.length)+"/300")])]),_c('div',{staticClass:"submit_box"},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){_vm.show_wrongmask = false}}},[_vm._v("取消")]),_c('button',{attrs:{"type":"button"},on:{"click":_vm.submitError}},[_vm._v("提交纠错")])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card_top_left"},[_c('div'),_vm._v("答题卡 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[_c('li',[_vm._v(" 已做 "),_c('span')]),_c('li',[_vm._v(" 未做 "),_c('span')]),_c('li',[_vm._v(" 当前 "),_c('span')])])}]

export { render, staticRenderFns }